import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["paginationForm"];

  selectPerPage(event) {
    event.preventDefault();
    this.paginationFormTarget.submit();
  }

}