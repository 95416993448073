/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2020 FotoFinder Systems GmbH
 ***
 ***  Last update: 20.02.20.stp
 ***
 *****************************************************************/


import { Controller } from "@hotwired/stimulus"

isOpenFilter = false

/*
  Unlink a registred device.
 */
export default class extends Controller {

  open(event) {
    event.preventDefault()
    this.auth_token = event.target.parentElement.nextSibling.value;
    this.device = event.target.dataset.value;
    this.url = event.target.parentElement.parentElement.getAttribute("action");
  }

  unlink() {
    const path = `${this.url}/${this.device}`;

    $.ajax({
       url: path,
       type: "DELETE",
       data: { authenticity_token: this.auth_token },
       success: function() {
        // we had to execute below line as button_to generates a form and 
        // multiple forms on the same page as same authenticity_token.
        // So we need to refresh the page for unique authenticity token
        window.location.href = window.location.href;
       }
    })
  }

  openFilter(e){
    e.stopPropagation();
    if(isOpenFilter){
      document.getElementById("filterDevice").classList.add("hidden")
    } else {
      document.getElementById("filterDevice").classList.remove("hidden")
    }
    isOpenFilter = !isOpenFilter
  }

  closeFilter(){
    if(isOpenFilter){
      document.getElementById("filterDevice").classList.add("hidden")
      isOpenFilter = false
    }
  }

  get device() {
    return this.data.get('device');
  }

  set device(value) {
    this.data.set('device', value);
  }

  set auth_token(value) {
    this.data.set('auth_token', value);
  }

  get auth_token() {
    return this.data.get('auth_token');
  }

}
