import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import { setToast } from "../utils";

export default class extends Controller {

    static targets = ["preview", "removeLogo"]

    selectFiles(e) {
        const file = e.target.files[0];
        if(file) {
            const reader = new FileReader();
            reader.onload = () => {
              this.previewTarget.src = reader.result;
            };
            reader.readAsDataURL(file);
        }
    }

    removeLogo(e) {
        Rails.ajax({
            type: "DELETE",
            format: 'js',
            url: e.currentTarget.dataset.url,
            data: "",
            beforeSend: () => { 
                return true;
            },
            success: () => { 
                this.previewTarget.src = "/assets/base/image-unavailable.jpg";
                setToast("Logo has been removed", "success");
                this.removeLogoTarget.remove();
            },
            error: () => {
                setToast("Some error occurred", "danger");
            }
            }) 
    }
}