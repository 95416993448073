import Modal from "../base_modal";

export default class extends Modal {
  static targets = [ "reasonChangePlan", "otherInput" ];

  open(event) {
    this.form = document.getElementById(event.currentTarget.dataset.formId);
    super.open(event);
  }

  reasonChangePlan(e) {
    e.preventDefault();
    this.changePlanTarget.dispatchEvent(new Event("click"));
  }

  openInput() {
    this.otherInputTarget.classList.remove("hidden");
    this.validateReasonSelection();
  }

  hiddenInput() {
    this.otherInputTarget.classList.add("hidden");
    this.validateReasonSelection();
  }

  validateReasonSelection() {
    if (this.form) {
      const radioButtons = this.form.querySelectorAll("input[type='radio']");
      const submitBtn = this.form.querySelectorAll("input[type='submit']")[0];

      const validForm = this.isRadioButtonSelected(radioButtons) && this.hasReason(radioButtons);

      if (validForm) {
        submitBtn.classList.remove("field-disabled");
        submitBtn.removeAttribute("disabled");
      } else {
        submitBtn.classList.add("field-disabled")
        submitBtn.setAttribute("disabled", "disabled")
      }
    }
  }

  hasReason(radioButtons) {
    // only for radio button "Other"
    let selectedRadio;
    radioButtons.forEach((radio) => {
      if (radio.checked) selectedRadio = radio;
    });

    if (selectedRadio && selectedRadio.value == "Other") {
      let reasonText = this.form.querySelectorAll("input[type='text']")[0];
      return (reasonText.value.length != 0);
    } else {
      return true;
    }
  }

  isRadioButtonSelected(radioButtons) {
    let isSelected = false;
    radioButtons.forEach((radio) => {
      if (radio.checked) isSelected = true;
    });
    return isSelected;
  }
}