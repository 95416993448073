var id = 0

export function setToast(content, type) {
  var HTMLElement = `
    <div class="pointer-events-auto toast-wrapper w-fit rounded-lg shadow-lg shadow-black/5" id="toast-${id}" role="alert">
      <div class="flex p-2 items-center justify-between text-white bg-bg${type} border border-b${type} gap-4 rounded-lg w-fit" data-controller="toast">
        <div class="flex gap-4 items-center">
          <div class="p-1 bg-ct${type} min-w-8 rounded-lg" >
            <img src="/assets/svg/toast/${type}.svg" >
          </div>
          <p class="text-sm" >${content}</p>
        </div>
        <img src="/assets/svg/toast/close.svg" data-id="${id}" class="cursor-pointer" data-action="click->toast#closeToast">
      </div>
    </div>
  `;
  document.getElementById("toast-block").innerHTML += HTMLElement;
  autoClose(id);
  id++;
}

function autoClose(closeId) {
  setTimeout(() => {
    document.getElementById(`toast-${closeId}`).remove();
  }, 5000);
}