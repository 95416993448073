import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {

    static targets = ["aiscore"]

    connect() {
      super.connect();
      // Get AI score of images
      this.aiscoreTargets.forEach(t => {
        const url = t.dataset.url;
        this.getAIScore(url);
      });
    }

    // Get AI Score
    getAIScore(url) {
        Rails.ajax({
        type: "GET",
        format: 'js',
        url: url,
        data: "",
        beforeSend: () => { 
            return true;
        },
        complete: () => { 
        },
        error: () => {
        }
        }) 
    }
}
