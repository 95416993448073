/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2020 FotoFinder Systems GmbH
 ***
 ***  Last update: 24.12.20.sc
 ***
 *****************************************************************/


import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["default", "enterprise"];

	show_fields(e) {
		var radio_btn_value = e.target.value;
		var wrapper_div = document.getElementById("max-space");
		wrapper_div.classList.toggle("hidden-overwrite");
	}

	show_enterprise(e) {
		this.toggleDivs();
	}

	toggleDivs() {
		const defaultRadio = document.getElementById("default");
		const enterpriseRadio = document.getElementById("enterprise");

		const enterpriseDivs = this.enterpriseTargets;

		if (defaultRadio.checked) {
			enterpriseDivs.forEach(div => div.classList.add('hidden'));
		} else if (enterpriseRadio.checked) {
			enterpriseDivs.forEach(div => div.classList.remove('hidden'));
		}
	}

	disableFields(div, disable) {
		div.querySelectorAll('input, select, textarea').forEach(field => {
			field.disabled = disable;
		});
	}

	handleSubmit(event) {
		const enterpriseDivs = this.enterpriseTargets;

		enterpriseDivs.forEach(div => {
			if (div.classList.contains('hidden')) {
				this.disableFields(div, true);
			}
		});
	}
}
