/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2020 FotoFinder Systems GmbH
 ***
 ***  Last update: 20.05.20.stp
 ***
 *****************************************************************/


import Modal from "../base_modal";
import { setToast } from "../utils"



export default class extends Modal {

  static targets = ["creditCount", "creditInput", "redirectUrl", "addMoreImages"]

  static values = {
    images: String,
    creditAmount: Number
  }

  spinner(e) {
    e.target.blur();
    this.lockScroll();
    this.containerTarget.classList.remove(this.toggleClass);
    if (!this.data.get("disable-backdrop")) {
      document.body.insertAdjacentHTML('beforeend', this.backgroundHtml);
      this.background = document.querySelector(`#${this.backgroundId}`);
    }
  }

  goto(e) {
    e.preventDefault();
    var current = e.target.parentNode.dataset.id;
    var current_elements = document.querySelectorAll("div.images_" + current);
    current_elements.forEach(current_element => current_element.classList.add("hidden"));
    // show pointer element
    var pointer_value = e.target.value;

    var elements = document.querySelectorAll("div.images_" + pointer_value);
    elements.forEach(element => element.classList.remove("hidden"));
    // set current image for gallery
    document.querySelector("#current-image").value = pointer_value;
    //select the gallery image
    this.set_active(pointer_value);
    this.set_inactive(current);
  }

  navigate_gallery(e) {
    e.preventDefault();
    var current_id = document.querySelector("#current-image").value;
    var target_id = (e.currentTarget.id).split("_").pop(); // "gallery_image_$(image_id)", split by dash and take the last element
    if (target_id != current_id) {
      var currentElements = document.querySelectorAll("div.images_"+current_id);
      currentElements.forEach((element) => { element.classList.add("hidden") });
      var targetElements = document.querySelectorAll("div.images_"+target_id);
      targetElements.forEach((element) => { element.classList.remove("hidden") });
      document.querySelector("#current-image").value = target_id;
      this.set_active(target_id);
      this.set_inactive(current_id);
    }
  }

  removeImage(e) {
    const target = e.currentTarget;
    const removeId = target.dataset.id;
    let images = this.imagesValue.split(",");
    if(images.length <= 1) {
      setToast("Can not remove the last image", "warning");
      return;
    }
    images = images.filter(s => s != removeId);
    this.element.dataset.changeExpertGroupImages = this.imagesValue =  images.join(",");
    window.history.replaceState(null, null, `${window.location.href.split("images=")[0]}images=${this.imagesValue}`);
    if(target.dataset.hasSecondOpinion == "false") {
      this.creditCountTarget.innerHTML = this.creditInputTarget.value = this.creditInputTarget.value - this.creditAmountValue;
    }
    document.getElementById(`gallery_image_${removeId}`).remove();
    document.querySelectorAll(`div.images_${removeId}`).forEach(e => e.remove());
    this.redirectUrlTargets.forEach(t => {
      t.href = `${decodeURIComponent(t.href).split("images=")[0]}images=${this.imagesValue}`;
    })
    this.addMoreImagesTarget.href = `${this.addMoreImagesTarget.href.split("so_images")[0]}so_images=${this.imagesValue}`;
    var current_id = document.querySelector("#current-image").value;
    if(current_id == removeId) {
      const target_id = images[0];
      var targetElements = document.querySelectorAll("div.images_"+target_id);
      targetElements.forEach((element) => { element.classList.remove("hidden") });
      document.querySelector("#current-image").value = target_id;
      this.set_active(target_id);
    }
  }

  set_active(id) {
    document.querySelector(`#gallery_image_${id} img`).classList.remove("border-ffblack");
    document.querySelector(`#gallery_image_${id} img`).classList.add("border-white");
    document.getElementById("gallery_image_line_" + id).classList.remove("hidden");
  }

  set_inactive(id) {
    document.querySelector(`#gallery_image_${id} img`).classList.remove("border-white");
    document.querySelector(`#gallery_image_${id} img`).classList.add("border-ffblack");
    document.getElementById("gallery_image_line_" + id).classList.add("hidden");
  }

  close(e) {
    e.preventDefault();
    document.querySelectorAll(".submit-so").forEach((submitBtn) => {
      submitBtn.disabled = true;
    })
    super.close(e);
    window.location.href = e.target.dataset.value;
  }
}
