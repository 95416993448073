/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2022 FotoFinder Systems GmbH
 ***
 ***  Last update: 24.12.20.sc
 ***
 *****************************************************************/


import Modal from "../base_modal";

export default class extends Modal {

    static targets = ['openButton']

    static values = {
        firstLogin: Boolean,
        userId: String
    }

    connect() {
        super.connect();
        if (document.cookie.match(`first_login_${this.userIdValue}`) == null) {
            let event = new Event("click")
            this.openButtonTarget.dispatchEvent(event)
            this.open(event)
        }
    }
    
    rememberFirstLogin() {
        let expiry_date = new Date();
        expiry_date.setTime(expiry_date.getTime() + (43200 * 1000)); // expires in 12 hours
        document.cookie = `first_login_${this.userIdValue}=true;expires=${expiry_date.toUTCString()};SameSite=Lax;`;
    }

    close(event) {
        this.rememberFirstLogin();
        super.close(event);
    }

    activate(e) {
        this.rememberFirstLogin();
        this.element.querySelector("form").submit();
    }

    subscribe(e) {
        this.rememberFirstLogin();
        super.close(e);
        window.location.href = e.target.getAttribute('href');
    }
}
