$(document).on('turbolinks:load', function() {

var qrBtn = document.getElementById("qrcode-login"); // open modal button
var qrModal = document.getElementById("qrcode-login-modal"); // modal container
var qrCodeContent = document.getElementById("qr-code-content"); // modal content (only QR code content)
var qrCloseModal = document.getElementById("close-qr-modal"); // close button
var qrSettingsDevicesURL = (qrBtn != undefined) ? qrBtn.dataset.url : ""; // Settings devices path
var QRStatusURL = `${qrSettingsDevicesURL}/auth_status`;
var QRCodeURL = `${qrSettingsDevicesURL}/qr_code`

  if (qrModal != null) { // no modal in HTML == no QR feature
    const POLL_INTERVAL = 1000;
    var WARNING_ISSUED = false;
    var QR_CONFIRMED = false;
    // events
    qrBtn.addEventListener("click", function(event) {
      event.preventDefault();
      // open the modal only if ping is successful
      fetch(QRStatusURL).then(response => response.json())
                        .then(response => { openModal(); })
                        .catch(error => { window.location.href = "/users/qr_timeout"; }); // ping is not successul == timeout (mostly)
    });

    qrCloseModal.addEventListener("click", function(event) {
      event.preventDefault();
      qrCloseModal.innerHTML = document.getElementById("modal-close-event").value;
      qrCloseModal.classList.add("field-disabled"); // css style of disabled button
      qrCloseModal.style.cssText += "color:#fff!important;" // forced, because of previous btn modal styling
      qrCloseModal.disabled = true;
      window.location.reload();
    });

    function openModal() {

      // write QR code
      fetch(QRCodeURL).then(response => response.text())
                .then(response => {
                  qrCodeContent.classList.remove("block"); // so it would in the center because of only 1 element
                  qrCodeContent.innerHTML = response;
                })
                .catch(error => { return error; }); // to keep browser console clean
      qrModal.classList.remove("hidden");
      startPolling();
    }

    function startPolling() {
      // fetch the URL to get QR status
      const checkQRStatus = () => {
        fetch(QRStatusURL).then(response => response.json()) // return it as json
                          .then(response => {
                            var qrStatus = response.qr_status;
                            var qrExpired = response.qr_expired;
                            if (qrExpired && !WARNING_ISSUED) {
                              var text = document.getElementById("qr-expired-warning").value;
                              var warningSpan = "<span class='block text-red-600 text-lg text-center'>"+text+"</span>"
                              qrCodeContent.insertAdjacentHTML("afterend", warningSpan);
                              WARNING_ISSUED = true; // just once
                            }
                            if (qrStatus && !QR_CONFIRMED) {
                              var text = document.getElementById("qr-confirmed-notification").value;
                              var confimationSpan = "<span class='block text-green-700 text-lg text-center'>"+text+"</span>"
                              qrCodeContent.insertAdjacentHTML("afterend", confimationSpan);
                              QR_CONFIRMED = true;
                              setTimeout(() => { window.location.reload(); }, 200);
                            }
                          });
      };
      setInterval(checkQRStatus, POLL_INTERVAL);
    }
  }
});