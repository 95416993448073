import { Alert } from "tailwindcss-stimulus-components";

export default class extends Alert {

  static targets = ["alertBox"];

  connect() {
  }

  closeFromContainer(event) {
    event.preventDefault();
    this.close();
  }

  show(event) {
    event.currentTarget.checked ? this.open() : this.close();
  }

  open() {
    this.alertBoxTarget.classList.remove("hidden");
    this.alertBoxTarget.animate([ { opacity: 1 } ], { duration: 150, iteration: 1 });
    setTimeout(() => {
      this.alertBoxTarget.classList.remove("opacity-0");
      this.alertBoxTarget.classList.add("opacity-100");
    }, 150);
  }

  close() {
    this.alertBoxTarget.animate([ { opacity: 0 } ], { duration: 300, iteration: 1 });
    setTimeout(() => {
      this.alertBoxTarget.classList.remove("opacity-100");
      this.alertBoxTarget.classList.add("hidden");
      this.alertBoxTarget.classList.add("opacity-0");
    }, 300);
  }

}