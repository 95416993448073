/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2023 FotoFinder Systems GmbH
 ***
 ***  Last update: 04.09.23.sc
 ***
 *****************************************************************/

import Modal from "../base_modal";
import Rails from "@rails/ujs";

export default class extends Modal {
  static targets = ["form", "checkbox", "selectAllCheckbox", "selectAll", "UnselectAll"]

  selectAllCheckboxClick(e) {
    this.selectUnSelectAll(e.target, e.target.checked)
  }

  selectAll(e) {
    e.preventDefault()
    this.selectUnSelectAll(e.target, true)
  }

  UnselectAll(e) {
    e.preventDefault()
    this.selectUnSelectAll(e.target, false)
  }

  callShowpriceImg(elements, checked) {
    $(this.element).find(elements).each((index, element) => {
      element.checked = checked;
    })
  }

  selectUnSelectAll(element, checked) {
    const selectClass = `.tab-content .image_select`;
    this.callShowpriceImg(selectClass, checked);
  }

  checkboxClick(e) {
    // Check preview image if exists
    const id = e.target.value;
    const previewImage = $(`input.image_preview[value="${id}"]`)[0];
    if(previewImage) previewImage.checked = e.target.checked;
  }

  checkSameImage(e) {
    const id = e.target.value;
    $(this.element).find(`.tab-content input.image_select[value="${id}"]`)[0].checked = e.target.checked;
  }

  // compatible for patient v1
  selectAllImages(event) {
    event.preventDefault();
    var selectClass = "";
    var consultationID = event.target.dataset.consultation;
    var bodypartType = event.target.dataset.bodypartType;

    if (consultationID !== undefined) { selectClass = `.image_select_consultation_${consultationID}`; }
    if (bodypartType !== undefined) { selectClass = `.image_select_bodypart_type_${bodypartType}`; }

    document.querySelectorAll(selectClass).forEach((element) => {
      element.checked = event.target.dataset.checked === "true" ? true : false;
      var creditMarker = document.getElementById("credit_marker_"+element.dataset.id);
      var totalPriceElement = document.getElementById("total-request-price");

      // calculate total price while considering the state of the checkbox
      var priceMarker = parseInt(document.getElementById("image_price_"+element.dataset.id).innerHTML) || 0;
      var totalPrice = parseInt(totalPriceElement.innerHTML) || 0;
      if (event.target.dataset.checked === "true") {
        if (creditMarker.classList.contains("hidden")) { totalPrice += priceMarker; }
        creditMarker.classList.remove("hidden");
      } else {
        if (!creditMarker.classList.contains("hidden")) { totalPrice -= priceMarker; }
        creditMarker.classList.add("hidden");
      }

      if (totalPrice <= 0) {
        totalPriceElement.innerHTML = "";
        totalPriceElement.classList.add("hidden");
      } else {
        totalPriceElement.innerHTML = "+"+totalPrice;
        totalPriceElement.classList.remove("hidden");
      }
    });
  }

  showPrice(element, checked) {
    element.checked = checked
    var credit_marker = document.querySelector("#credit_marker_" + element.dataset.id);
    var total_price_container = document.querySelector("span#total-request-price");
    if(total_price_container == null) return

    // get price
    var price = parseInt(document.querySelector("span#image_price_" + element.dataset.id).innerHTML);
    var total_price = parseInt(total_price_container.innerHTML) || 0; // if NaN

    // hide/show credit indicator and calculation of total price
    if (Array.from(credit_marker.classList).includes("hidden")) {
      credit_marker.classList.remove("hidden");
      total_price += price;
    } else {
      credit_marker.classList.add("hidden");
      total_price -= price;
    }
    // hide/show total price
    if (total_price > 0) {
      total_price_container.innerHTML = "+" + total_price;
      total_price_container.classList.remove("hidden");
    } else {
      total_price_container.innerHTML = ""; // empty the container
      total_price_container.classList.add("hidden");
    }
  }

  //
  // Checked, if images are selected.
  //
  confirm(action) {
    this.form = $('#action_form');
    this.form.attr('action', this.href);
    this.image_count = this.form.find('.form-checkbox:checked:not(.image_preview)').length;

    if (this.image_count > 0) {
      action()
    } else {
      this.showOverlay();
    }
  }
  // Check number of images selected for Proposer
  confirmProposer(action) {
    this.form = $('#action_form');
    this.form.attr('action', this.href);
    this.image_count = this.form.find('.form-checkbox:checked:not(.image_preview)').length;

    if (this.image_count > 0 && this.image_count < 2) {
      action()
    } else if(this.image_count <= 0) {
      this.showOverlayProposer(true);
    } else {
      this.showOverlayProposer(false);
    }
  }

  closeOverlay(e) {
    e.preventDefault();
    e.target.classList.add("hidden");
    document.querySelectorAll(".form-checkbox").forEach((element) => {
      element.classList.remove("z-10", "ring-4", "ring-red-500");
    });
  }

  showOverlay() {
    document.querySelector("#overlay").classList.remove("hidden"); // container
    // get open tabs
    var open_tabs = [];
    document.querySelectorAll(".tab-content").forEach((tab_content) => {
      if (tab_content.offsetHeight > 0) open_tabs.push(tab_content);
    });
    open_tabs.forEach((tab) => {
      tab.querySelectorAll(".form-checkbox").forEach((checkbox) => {
        checkbox.classList.add("z-10", "ring-4", "ring-red-500");
      });
    });
  }

  showOverlayProposer(isNoImageSelected) {
    document.querySelector(isNoImageSelected? "#overlay-proposer-no-selected": "#overlay-over-selected").classList.remove("hidden"); // container
    // get open tabs
    var open_tabs = [];
    document.querySelectorAll(".tab-content").forEach((tab_content) => {
      if (tab_content.offsetHeight > 0) open_tabs.push(tab_content);
    });
    open_tabs.forEach((tab) => {
      tab.querySelectorAll(".form-checkbox")[0].classList.add("z-10", "ring-4", "ring-red-500");
    });
  }

  //
  // Confirm: Delete images
  //
  confirm_delete(event) {
    event.preventDefault();
    this.confirm(() => {
      let str;
      const confirm_box = $('.delete_confirm #delete-images');
      const linkref = $('#action_delete').attr('href');
      if(this.form.find(".image_primary").length > 0) {
        confirm_box.find('p.title').html(this.data.get("delete-title"));
        confirm_box.find('p.desc').html(this.data.get("delete-confirm"));
      } else {
        if (this.image_count === 1) {
          str = this.image_count + " " + document.querySelector("span#image-translated").innerHTML;
        } else {
          str = this.image_count + " " + document.querySelector("span#images-translated").innerHTML;
        }
        confirm_box.find('span.count').html(str);
      }
      confirm_box.find('input:submit').click(() => this.form.attr('action', linkref).submit())

      // show content
      if (document.querySelector("#delete-images") !== undefined) {
        document.querySelector("#delete-images").classList.remove("hidden");
      } else {
        document.querySelector("#delete-images_group").classList.remove("hidden");
      }
      super.open(event);
    })
  }

  //
  // Confirm: PDF report
  //
  confirm_pdf(event) {
    event.preventDefault();

    this.confirm(() => {
      const linkref = $('#action_pdf').attr('href');
      this.form.attr("target", "_blank");
      this.form.attr('action', linkref).submit();
    })
  }

  // Confirm: Redirect to Proposer
  confirm_proposer(event) {
    event.preventDefault();

    this.confirmProposer(() => {
      const linkref = $("#action_proposer").attr("href");
      var selected_image = '';
      this.form.find('input[name="image_ids[]"]').each((i, cbox)  => {
        if (cbox.checked) {
          selected_image = document.querySelector(`#proposer_${cbox.value}`).value;
        }
      });
      window.location.href = `${linkref}?uuid=${selected_image}`;
    })
  }


  confirm_images(event) {
    event.preventDefault();

    this.confirm(() => {
      var selected_images = [];
      const linkref = $("#action_images").attr("href");
      var open_modal = false;
      this.form.find('input[name="image_ids[]"]').each((i, cbox) => {
        if (cbox.checked) {
          selected_images.push(cbox.value);
          if (document.querySelector("#has_second_opinion_" + cbox.value).value === "true") {
            open_modal = true;
          }
        }
      });
      if (open_modal) {
        // show content
        const confirm_box = this.form.find('.delete_confirm #create-second-opinion');
        document.querySelector("#create-second-opinion").classList.remove("hidden");
        if(this.form.find(".image_primary").length > 0) {
          confirm_box.find('p.title').html(this.data.get('so-title'));
          confirm_box.find('p.desc').html(this.data.get('so-confirm'));
        }
        // add the links in modal 'Yes' button
        document.querySelector("#multiple-so-images-yes").href = linkref + "?images=" + selected_images;
        super.open(event);
      } else {
        window.location.href = linkref + "?images=" + selected_images;
      }
    });
  }

  compare_images(e) {
    e.preventDefault();
    this.form = $('#action_form');
    const checkedImages = this.form.find('.form-checkbox:checked:not(.image_preview)');

    if (checkedImages.length > 2) {
      alert("More than 2 selected is not allowed");
    } else {
      const url = $("#action_compare").attr("href");
      let selectedImages = [];
      checkedImages.each((index, ele) => {
        selectedImages.push(ele.value);
      });
      window.location.href = `${url}&images=${selectedImages.join(",")}`;
    }
  }

  searchByTreatment(event) {
    event.preventDefault();
    var linkref = document.querySelector("#searchTreatment").href;
    var treatmentKeyword = document.querySelector("#treatment-keyword").value;

    window.location.href = `${linkref}&treatment_keyword=${treatmentKeyword}`
  }

  searchTreatmentByKey(event) {
    if (event.keyCode === 13) this.searchByTreatment(event);
  }

  close(event) {
    // reset and hides all content
    let createSecondOpinions = document.querySelector("#create-second-opinion");
    if(createSecondOpinions) createSecondOpinions.classList.add("hidden");

    let deleteImages = document.querySelector("#delete-images");
    if(deleteImages) deleteImages.classList.add("hidden");
    super.close(event);
  }

  confirm_request_so(event) {
    window.location.href = document.querySelector("#multiple-so-images-yes").href;
    super.close(event);
  }

  // view images for unknown bodyparts
  viewImages() {
    var bodypartCheckbox = document.querySelector("#bodypart-input-empty")
    bodypartCheckbox.checked = !bodypartCheckbox.checked;
  }
  
}