/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2023 FotoFinder Systems GmbH
 ***
 ***  Last update: 15.02.23.sc
 ***
 *****************************************************************/

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  filterByDoctor(event) {
    event.preventDefault();
    var baseURL = new URL(document.querySelector("#base-filter-doctor-url").value);
    var userID = event.target.value;
    baseURL.searchParams.set("user_id", userID);
    window.location.href = baseURL.href;
  }

  resetFilter(event) {
    event.preventDefault();
    var baseURL = new URL(document.querySelector("#base-filter-doctor-url").value);
    baseURL.searchParams.delete("user_id")
    window.location.href = baseURL.href;
  }

  signConsent(event) {
    // have to use getElementById because there 2 hidden fields in the form (mobile and web)
    var marketingConsentField = document.getElementById(event.currentTarget.dataset.value);
    if (event.currentTarget.checked) {
      // use unix timestamp so it's easy to convert in Mongoid later
      marketingConsentField.value = parseInt(Date.now()/1000);
    } else {
      // nullify the field
      marketingConsentField.value = "";
    }
  }

  goToDetails(event) {
    event.preventDefault();
    var url = event.currentTarget.dataset.link;
    window.location.href = url;
  }
}