import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  saveToCookie(event) {
    event.preventDefault();
    // cookieType is group_by or filter_by
    var cookieType = event.target.dataset.cookieType; // possible values: group and filter
    // groupType possible values: session (empty), localization, and treatment
    // filterType possible values: all (empty), dermoscopy, and aesthetics
    var attributeType = event.target.dataset.filterType;
    var userID = document.getElementById("user-identification").value;
    document.cookie = userID+"-"+cookieType+"="+attributeType+";max-age=Session;Same-Site=None; Secure; path=/";
    window.location = event.target.dataset.url;
  }

}